/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useMemo } from "react";
import { AspectRatio, Card, Grid, Icon, Stack, Text } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import { etV2 } from "utils/etV2";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useCreateUrl from "hooks/useCreateUrl";
import Container from "components/elements/Container";
import useGlobalContext from "hooks/useGlobalContext";
import styles from "./HomeRecentSearch.module.css";
import useHomeRecentSearch from "./useHomeRecentSearch.controller";
import { RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS } from "../../../constants";
import { FilterFunnelIcon } from "@bookingcom/bui-assets-react/streamline";
import { trackExperiment } from "utils/et";

export default function HomeRecentSearch() {
  const { apiPrefix } = useGlobalContext();
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const isMeta = isOfMetaOrigin();
  const { createUrl } = useCreateUrl();
  const { images, searchHistoryList, generateSearchUrlFromSearchHistory } = useHomeRecentSearch();

  const canRender = searchHistoryList.length !== 0 && !isMobile;

  const anyWithFilters = useMemo(() => {
    return searchHistoryList.some((i) => {
      return !!Object.keys(i.searchhistoryItem).some((key) => {
        return !!RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS.includes(key);
      });
    });
  }, [searchHistoryList]);

  const withFilters = anyWithFilters && !!trackExperiment("flights_web_ddot_recent_search_with_filters");

  useEffect(() => {
    if (!anyWithFilters) return;
    etV2.stage("flights_web_ddot_recent_search_with_filters", 1);
    etV2.stage("flights_web_ddot_recent_search_with_filters", isMeta ? 3 : 2);
  }, [anyWithFilters, isMeta]);

  const filtersCount = useCallback((i: SearchHistory) => {
    return Object.keys(i).filter((k) => RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS.includes(k)).length;
  }, []);

  if (!canRender) return null;

  return (
    <Container pb={isMobile ? 8 : 4}>
      <Stack gap={4}>
        <Stack.Item>
          <Text tagName="h2" variant="headline_2">
            {i18n.trans(t("flights_continue_search_title"))}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Grid attributes={{ style: { alignItems: "stretch" } }}>
            {searchHistoryList.map((item, idx) => (
              <Grid.Column size={isMobile ? 12 : 4} key={item.title || "" + item.subtitle + idx}>
                <Card variant="elevated" attributes={{ style: { height: "100%" } }}>
                  <a
                    style={{ color: "inherit", textDecoration: "none" }}
                    href={
                      apiPrefix + createUrl(generateSearchUrlFromSearchHistory(item.searchhistoryItem, withFilters))
                    }
                    onClick={() => {
                      etV2.customGoal("flights_web_ddot_recent_search_with_filters", 1);
                      etV2.customGoal(
                        "flights_web_ddot_recent_search_with_filters",
                        !!filtersCount(item.searchhistoryItem) ? 3 : 2
                      );
                    }}
                  >
                    <Stack direction="row" gap={3}>
                      <Stack.Item>
                        <AspectRatio
                          attributes={{
                            style: {
                              borderRadius: "var(--bui_border_radius_100)",
                              backgroundColor: "var(--bui_color_foreground_inverted)",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                              backgroundImage: `url("https://q-xx.bstatic.com${
                                images?.[item?.photoIata || ""]?.["square210"]
                              }")`
                            }
                          }}
                          ratio="1:1"
                          width="64px"
                        />
                      </Stack.Item>
                      <Stack.Item grow>
                        <Grid gap={2}>
                          <Grid.Column size={12}>
                            <div>
                              <Text className={styles.ellipsis} variant="strong_2">
                                {item.title}
                              </Text>
                              <Text className={styles.ellipsis} variant="body_2">
                                {item.subtitle}
                              </Text>
                              {withFilters && !!filtersCount(item.searchhistoryItem) ? (
                                <Text mixin={{ paddingBlockStart: 1 }}>
                                  <Icon svg={FilterFunnelIcon} mixin={{ marginInlineEnd: 1 }} />
                                  {i18n.trans(
                                    t("flights_continue_search_filter_applied", {
                                      variables: {
                                        num_filters: filtersCount(item.searchhistoryItem),
                                        num_exception: filtersCount(item.searchhistoryItem)
                                      }
                                    })
                                  )}
                                </Text>
                              ) : undefined}
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Stack.Item>
                    </Stack>
                  </a>
                </Card>
              </Grid.Column>
            ))}
          </Grid>
        </Stack.Item>
      </Stack>
    </Container>
  );
}
