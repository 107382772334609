import {
  AttractionsIcon,
  BeachIcon,
  BeachPalmSunbedIcon,
  CameraIcon,
  CarRetroLuggageIcon,
  CastleIcon,
  CityIcon,
  FamilyChildTeeterIcon,
  FoodGourmetIcon,
  HeartOutlineIcon,
  LakeViewIcon,
  LandmarkIcon,
  NauticSportsWaterSkiingIcon,
  PlayingCardsIcon,
  PartyMusicDanceIcon,
  SavingPiggyDollarsIcon,
  ShoppingBagIcon,
  SpaIcon,
  SportsHikingIcon,
  SportsSurfIcon,
  SportsWinterFreeSkiingIcon,
  TransportBoatIcon,
  TigerIcon,
  TreeIcon,
  VolcanoIcon,
  WineIcon
} from "@bookingcom/bui-assets-react/streamline";

export const FlightsThemesIcons = {
  "16": CityIcon,
  "888": BeachPalmSunbedIcon,
  "23": HeartOutlineIcon,
  "11": BeachIcon,
  "88": SportsHikingIcon,
  "73": FamilyChildTeeterIcon,
  "19": CameraIcon,
  "29": PartyMusicDanceIcon,
  "21": FoodGourmetIcon,
  "28": ShoppingBagIcon,
  "30": SavingPiggyDollarsIcon,
  "1": TransportBoatIcon,
  "14": LandmarkIcon,
  "82": TreeIcon,
  "79": SpaIcon,
  "62": WineIcon,
  "69": SportsWinterFreeSkiingIcon,
  "18": CastleIcon,
  "87": LakeViewIcon,
  "4": NauticSportsWaterSkiingIcon,
  "92": AttractionsIcon,
  "10": SportsSurfIcon,
  "102": TigerIcon,
  "118": PlayingCardsIcon,
  "81": VolcanoIcon,
  "101": CarRetroLuggageIcon
};
