import { createTrackingUtils } from "utils/experiments";
import { Request } from "express";

export const trackGeniusAirlinesIndexCampaign = createTrackingUtils({
  name: "flights_web_cat_genius_airlines_campaign_index",
  stages: {
    displayed_on_page: 4,
    displayed_on_page_2: 5,
    displayed_on_page_3: 6
  },
  goals: {
    search_button_click: 1,
    tooltip_hover: 2
  },
  goalsWithValue: []
});

export function trackGeniusAirlinesIndexStages(req: Request) {
  if (req.userInfo?.geniusLevel >= 2) {
    req.et?.trackExperiment("flights_web_cat_genius_airlines_campaign_index");
    req.et?.trackExperimentStage("flights_web_cat_genius_airlines_campaign_index", 1);

    if (req.userInfo?.geniusLevel === 2) {
      req.et?.trackExperimentStage("flights_web_cat_genius_airlines_campaign_index", 2);
    }

    if (req.userInfo?.geniusLevel === 3) {
      req.et?.trackExperimentStage("flights_web_cat_genius_airlines_campaign_index", 3);
    }
  }
}
