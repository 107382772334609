import useEvents from "hooks/useEvents";
import useGlobalContext from "hooks/useGlobalContext";
import { useEffect, useRef } from "react";
import { trackExperimentStage } from "utils/et";
import { isFeatureRunningClientSide } from "utils/features";

export function useTrackHomePageTrafficClientAA() {
  const context = useGlobalContext();
  const grumble = useRef(useEvents().grumble).current;

  useEffect(() => {
    // Checking if context was populated with real values and not called with default values, requestId is empty by default
    if (!context.requestId) {
      grumble("useEffect on Home page is triggered when context is empty", {
        userAgent: context.userAgent,
        ipCountry: context.ipCountry
      });
      return;
    }

    trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 1); // all users that reached client side

    if (!context.userAgent.isBot && !context.isKnownBot) {
      trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 2); // normal users

      if (
        !context.userAgent.isTablet &&
        !context.userAgent.isMobile &&
        !context.userAgent.isWebview &&
        !!context.userAgent.browser
      ) {
        trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 3); // desktop only, excluding tablet users
      }

      if (context.userAgent.isMobile) {
        trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 4);
      }

      if (context.userAgent.isTablet) {
        trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 5);
      }

      if (context.userAgent.isWebview) {
        trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 6);
      }

      if (
        !context.userAgent.isTablet &&
        !context.userAgent.isMobile &&
        !context.userAgent.isWebview &&
        !context.userAgent.browser
      ) {
        trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 7); // something completely odd

        if (isFeatureRunningClientSide("FLIGHTS_WEB_CAT_UNUSUAL_TRAFFIC_HOME_GRUMBLE")) {
          grumble("Home page completely undefined client", {
            userAgent: context.userAgent,
            ipCountry: context.ipCountry
          });
        }
      }
    }

    if (context.userAgent.isBot) {
      trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 8);
      if (isFeatureRunningClientSide("FLIGHTS_WEB_CAT_UNUSUAL_TRAFFIC_HOME_GRUMBLE")) {
        grumble("Home page [unknown] bot view", { userAgent: context.userAgent, ipCountry: context.ipCountry });
      }
    }

    if (context.isKnownBot) {
      trackExperimentStage("flights_web_cat_aa_home_page_traffic_client", 9);
      if (isFeatureRunningClientSide("FLIGHTS_WEB_CAT_UNUSUAL_TRAFFIC_HOME_GRUMBLE")) {
        grumble("Home page [known] bot view", { userAgent: context.userAgent, ipCountry: context.ipCountry });
      }
    }
  }, [context, grumble]);
}
