import React, { useCallback } from "react";
import { AspectRatio, Box, Image, Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";

import { currencySymbolByPointOfSale } from "data/pointOfSaleToCurrency";
import { IMAGE_STATIC_ASSETS_URL, SPACER_PADDED } from "../../../constants";
import { UICheapFlightComponent, UICheapFlightInfo } from "@flights/types";
import getRoundtripSearchUrl from "utils/search-urls";
import { getMarketingTrackingVariable } from "utils/marketing-url-params";
import styles from "./CheapFlightsItem.module.css";
import { trackIndexCampaign } from "utils/experiments/customer-aquisition/flights_web_cat_banner_campaign_index";

import useGlobalContext from "hooks/useGlobalContext";
import useFormatPrice from "hooks/useFormatPrice";
import useCreateUrl from "hooks/useCreateUrl";
import useTrackFlightsWebUseOfferPos from "hooks/useTrackFlightsWebUseOfferPosExp";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";

interface Props {
  flight: UICheapFlightInfo;
  index: number;
  component: UICheapFlightComponent;
  isMobile?: boolean;
}

interface Image {
  src: string;
  alt: string;
}

const getImage = (toCityImageUrl: string, toCityName: string): Image => {
  return {
    src: `${IMAGE_STATIC_ASSETS_URL}${toCityImageUrl}`,
    alt: toCityName
  };
};

const getSearchUrl = (flight: UICheapFlightInfo): string => {
  return getRoundtripSearchUrl(
    {
      fromIata: flight.fromCityIata,
      toIata: flight.toCityIata,
      departureDate: flight.tripStartDate,
      returnDate: flight.tripEndDate
    },
    "flights_index_cf"
  );
};

const FlightsNearbyItem: React.FunctionComponent<Props> = ({ flight, index, component, isMobile }) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const { ipCountry, indexPageCampaign } = useGlobalContext();
  const flightsWebUseOfferPosExp = useTrackFlightsWebUseOfferPos();

  const metaSalesCountry = flightsWebUseOfferPosExp.isVariant && getMarketingTrackingVariable("salesCountry");
  const { createUrlWithApiPrefix } = useCreateUrl();
  const { formatPrice } = useFormatPrice();
  const trackLandingPageEvents = useLandingPageEvents();

  const routeName = i18n.trans(
    t("seo_flights_new_index_lp_cheap_flights_meta_flight_route", {
      variables: { city_name_origin: flight.fromCityNameLocalised, city_name_destination: flight.toCityNameLocalised }
    })
  );

  const fromDateFormatted = formats.flightDate(flight.tripStartDate);
  const toDateFormatted = formats.flightDate(flight.tripEndDate);
  const flightParams =
    `${fromDateFormatted} - ${toDateFormatted}` +
    SPACER_PADDED +
    `${i18n.trans(t("seo_flights_new_index_lp_cheap_flights_meta_round_trip"))}`;

  // Optionally show price information.
  const currency = currencySymbolByPointOfSale[(metaSalesCountry || ipCountry).toLowerCase() as string];
  const fromPriceFormated = flight.price?.units
    ? formatPrice({ currencyCode: currency, units: flight.price?.units, nanos: 0 })
    : "";
  const getFromPrice = () =>
    `${i18n.trans(
      t("seo_flights_new_index_lp_cheap_flights_meta_flight_from_price", {
        variables: { flight_price: fromPriceFormated, num_exception: 1 }
      })
    )}`;

  const url = createUrlWithApiPrefix(getSearchUrl(flight));
  const handleClick = useCallback(() => {
    if (indexPageCampaign) {
      trackIndexCampaign.goals.carousels_click();
    }

    trackLandingPageEvents("click", component, { ...flight, position: index + 1, url });
  }, [component, flight, index, indexPageCampaign, trackLandingPageEvents, url]);

  const imageUrl = !isMobile
    ? flight.toCityOtherImageSlugs?.["526x420:webp"]?.imageUrlSlug?.[0]
    : flight.toCityImageUrl;

  return (
    <a className={styles.anchorContainer} href={url} onClick={handleClick} onAuxClick={handleClick}>
      <Box padding={0}>
        <AspectRatio ratio="4:3">
          <Image
            className={styles.image}
            {...getImage(imageUrl || "", routeName)}
            fallback="image"
            fallbackImageSrc={`${IMAGE_STATIC_ASSETS_URL}${flight.toCityImageUrl}`}
          />
        </AspectRatio>
        <div className={styles.textContainer}>
          <Text className={styles.textLine} variant="strong_1">
            {routeName}
          </Text>
          <Text className={styles.textLine} variant="body_2" color="neutral_alt">
            {flightParams}
          </Text>
          {fromPriceFormated && (
            <Text className={styles.textLine} variant="body_2" color="neutral_alt">
              {getFromPrice()}
            </Text>
          )}
        </div>
      </Box>
    </a>
  );
};
export default FlightsNearbyItem;
