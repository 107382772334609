import { UICarrierCheckinInfo, UIFlightSegment, UILeg } from "@flights/types";
import { isFlightSegmentInThePast } from "utils/past-booking";

export interface FilteredLegWithOpenedCheckIn {
  leg: UILeg;
  checkinInfoForLeg: UICarrierCheckinInfo;
}
export const getFilteredLegsWithOpenedCheckIn = (
  flightSegments: UIFlightSegment[],
  carrierCheckinInfo: UICarrierCheckinInfo[] | undefined
): FilteredLegWithOpenedCheckIn[] => {
  let nextLegIsVi = false;
  const filteredLegs: FilteredLegWithOpenedCheckIn[] = [];

  flightSegments.some((segment, segmentIndex) => {
    // Filter out past segments
    if (isFlightSegmentInThePast(segment)) {
      return;
    }

    segment.legs.forEach((leg, legIndex) => {
      // check in should be displayed only for the first leg
      // or for the leg right after the VI leg
      if (nextLegIsVi || legIndex === 0) {
        // Finding a checkin info for the leg
        const checkinInfoForLeg = carrierCheckinInfo?.find(
          (checkinInfo) =>
            checkinInfo.legIdentifier.segmentIndex === segmentIndex && checkinInfo.legIdentifier.legIndex === legIndex
        );

        if (checkinInfoForLeg) {
          filteredLegs.push({ leg, checkinInfoForLeg });
        }
      }
      nextLegIsVi = !!leg.vi?.protected;
    });

    // returning true to break .some loop
    return true;
  });

  return filteredLegs;
};
