import { Banner, Icon, Image, Popover, Stack, Text } from "@bookingcom/bui-react";
import React, { memo, useEffect, useMemo } from "react";
import Frame from "../Frame/Frame";
import Container from "components/elements/Container";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline";
import { trackGeniusAirlinesIndexCampaign } from "utils/experiments/customer-aquisition/flights_web_cat_genius_airlines_campaign_index";
import styles from "./GeniusAirlinesBanner.module.css";
import { getIndexCampaigns } from "store/indexCampaigns/selectors";
import { useSelector } from "react-redux";
import { getUserInfo } from "store/user/selectors";

export const GeniusAirlinesBanner = memo(function _GeniusAirlinesBanner() {
  const geniusAirlineBannerData = useSelector(getIndexCampaigns).geniusAirlines;
  const user = useSelector(getUserInfo);

  useEffect(() => {
    if (user && user.geniusLevel >= 2) {
      trackGeniusAirlinesIndexCampaign.stages.displayed_on_page();

      if (user.geniusLevel === 2) {
        trackGeniusAirlinesIndexCampaign.stages.displayed_on_page_2();
      }

      if (user.geniusLevel === 3) {
        trackGeniusAirlinesIndexCampaign.stages.displayed_on_page_3();
      }
    }
  }, [user]);

  const carriersList = useMemo(() => {
    if (!geniusAirlineBannerData) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="space-between" attributes={{ role: "listbox" }}>
        {geniusAirlineBannerData.airlines.map(({ name, imageUrl }) => (
          <>
            {!!name && !!imageUrl && (
              <div style={{ flex: 1 }} key={name} role="listitem">
                <Stack justifyContent="space-between" alignItems="center">
                  <Image width="32px" src={imageUrl} alt="" />
                  <Text variant="small_2" align="center">
                    {name}
                  </Text>
                </Stack>
              </div>
            )}
          </>
        ))}
      </Stack>
    );
  }, [geniusAirlineBannerData]);

  if (!geniusAirlineBannerData) {
    return null;
  }

  return (
    <Container>
      <Frame mt={6} mb={8}>
        <Banner
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <Text variant="strong_1" attributes={{ "aria-describedby": "genius_airlines_tooltip" }}>
                {geniusAirlineBannerData.title}
              </Text>

              {geniusAirlineBannerData.tooltip && (
                <Popover
                  position="top-start"
                  forcePosition
                  triggerType="hover"
                  hideClose={true}
                  onOpen={trackGeniusAirlinesIndexCampaign.goals.tooltip_hover}
                >
                  <Popover.Trigger>
                    {(attributes) => (
                      <div {...attributes} className={styles.tooltip__icon__wrapper}>
                        <Icon svg={<InfoSignIcon />} size="smallest" />
                      </div>
                    )}
                  </Popover.Trigger>
                  <Popover.Content
                    attributes={{
                      id: "genius_airlines_tooltip",
                      role: "tooltip"
                    }}
                  >
                    <Text variant="body_1">{geniusAirlineBannerData.tooltip}</Text>
                  </Popover.Content>
                </Popover>
              )}
            </Stack>
          }
          dismissible={false}
        >
          <Frame mt={1}>{carriersList}</Frame>
        </Banner>
      </Frame>
    </Container>
  );
});
