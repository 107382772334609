import React from "react";

import { Grid, GridColumn, Image, Text } from "@bookingcom/bui-react";
import { Trans, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import Container from "../Container";
import useIsBR from "hooks/useIsBR";
import { trackExperiment } from "utils/et";
import useGlobalContext from "hooks/useGlobalContext";
import Frame from "../Frame";
import { mcn } from "utils/mergeClassnames";
import styles from "./UspBlock.module.css";

interface Props {
  hideBackground?: boolean;
}

export type USP = {
  asset: {
    setName: "illustrations-traveller";
    assetName: "CustomerSupport" | "MagnifyingGlassUsp" | "MoneyUsp" | "TicketsUsp";
  };
  header: string;
  subheader: string;
};

export type USPs = USP[];

const UspBlock: React.FunctionComponent<Props> = ({ hideBackground = false }) => {
  const i18n = useI18n();

  const isBR = useIsBR();
  const { isCustomerServiceEnabledForPOS } = useGlobalContext();
  const showCsUsp = isCustomerServiceEnabledForPOS && !!trackExperiment("flights_web_cs_usp_index");

  const usps: USPs = [
    showCsUsp
      ? {
          asset: {
            setName: "illustrations-traveller",
            assetName: "CustomerSupport"
          },
          header: i18n.trans(t("flights_index_cs_usp_header")),
          subheader: i18n.trans(t("flights_index_cs_usp_subheader"))
        }
      : {
          asset: {
            setName: "illustrations-traveller",
            assetName: "MagnifyingGlassUsp"
          },
          header: i18n.trans(t("flights_index_usp1_header")),
          subheader: i18n.trans(t("flights_index_usp1_copy"))
        },
    isBR
      ? {
          asset: {
            setName: "illustrations-traveller",
            assetName: "MoneyUsp"
          },
          header: i18n.trans(t("flights_index_instalment_usp_header")),
          subheader: i18n.trans(t("flights_index_instalment_usp_copy"))
        }
      : {
          asset: {
            setName: "illustrations-traveller",
            assetName: "MoneyUsp"
          },
          header: i18n.trans(t("flights_index_usp2_header")),
          subheader: i18n.trans(t("flights_index_usp2_copy"))
        },
    {
      asset: {
        setName: "illustrations-traveller",
        assetName: "TicketsUsp"
      },
      header: i18n.trans(t("flights_index_usp3_header")),
      subheader: i18n.trans(t("flights_index_usp3_copy"))
    }
  ];

  return (
    <>
      <Container className={mcn(!hideBackground && styles.container)}>
        <Grid className={styles.grid}>
          {usps.map((usp: USP, index: number) => (
            <GridColumn
              className={styles.uspContainer}
              key={index}
              size={{
                s: 12,
                m: 4
              }}
            >
              <Frame wrap="nowrap" alignItems="center" direction="row">
                <Frame className={styles.uspImageWrapper} mr={4}>
                  <Image
                    asset={usp.asset}
                    width="80px"
                    height="80px"
                    contentMode="fit"
                    attributes={{ "aria-hidden": true }}
                  />
                </Frame>
                <div>
                  <Text variant="headline_3">{usp.header}</Text>
                  <Text variant="body_2" className={styles.subheader}>
                    {usp.subheader}
                  </Text>
                </div>
              </Frame>
            </GridColumn>
          ))}
        </Grid>
      </Container>
      <Container className={styles.disclaimerContainer}>
        <Text variant="body_2" className={styles.disclaimer}>
          <Trans tag="flights_index_usp3_disclaimer" />
        </Text>
      </Container>
    </>
  );
};

export default UspBlock;
