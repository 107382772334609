import React from "react";

import { Breadcrumbs, Divider, Stack, useTheme } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { UICarrierCheckinInfo, UIFlightSegment, UILeg, UIOrder } from "@flights/types";
import useCreateUrl from "hooks/useCreateUrl";

import ConfirmationController from "../ConfirmationController";
import { CheckInInfoCard } from "./CheckinInfoCard";
import TwoColumnLayout from "../TwoColumnLayout";
import { CheckinHeader } from "./CheckinHeader";
import { CheckinHowTo } from "./CheckinHowTo";
import { CheckinSegmentHeader } from "./CheckinSegmentHeader";

interface WebCheckinInnerDesktopProps {
  order: UIOrder;
  segmentIndex: number;
  segment: UIFlightSegment;
  leg: UILeg;
  checkinInfoForLeg: UICarrierCheckinInfo;
}

export const WebCheckinInnerDesktopV2: React.FC<WebCheckinInnerDesktopProps> = ({
  order,
  segmentIndex,
  segment,
  leg,
  checkinInfoForLeg
}) => {
  const i18n = useI18n();
  const theme = useTheme();
  const { createUrl } = useCreateUrl();

  const { isOnlineCheckinOpen, legIdentifier } = checkinInfoForLeg;

  return (
    <ConfirmationController>
      {() => (
        <main>
          <Breadcrumbs
            back
            items={[
              {
                text: i18n.trans(t("flights_pb_checkin_navigation")),
                href: createUrl(`/booking/order-details/${order?.orderToken}`)
              }
            ]}
            attributes={{ style: { paddingBottom: theme.units.spacing_2x } }}
          />
          <Stack gap={6}>
            <CheckinHeader segment={segment} />
            <TwoColumnLayout>
              <div style={{ marginBottom: theme.units.spacing_8x }}>
                <Stack gap={8}>
                  <CheckinHowTo isCheckInExperiment order={order} />
                  <Divider />
                  <Stack gap={4}>
                    <CheckinSegmentHeader segment={segment} showSubHeader={true} />
                    <CheckInInfoCard
                      leg={leg}
                      segment={segment}
                      segmentIndex={segmentIndex}
                      legIndex={legIdentifier.legIndex}
                      openCheckin={isOnlineCheckinOpen}
                      checkInInfo={checkinInfoForLeg}
                      showVuelingAlert
                    />
                  </Stack>
                </Stack>
              </div>
            </TwoColumnLayout>
          </Stack>
        </main>
      )}
    </ConfirmationController>
  );
};
