import { AspectRatio, Box, Carousel, Image, Text } from "@bookingcom/bui-react";
import { SPACER_PADDED } from "../../../constants";
import React, { memo, useCallback, useMemo } from "react";
import { UIFlightsThemeRoute } from "@flights/types";
import styles from "./styles.module.css";
import useCreateUrl from "hooks/useCreateUrl";
import useGlobalContext from "hooks/useGlobalContext";
import { addMonths, format } from "date-fns";
import TripType from "../TripSummary/components/TripType";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { trackCustomGoal } from "utils/et";

export const ThemeCarousel = memo(function _ThemeCarousel({
  themeId,
  destinations,
  isMobile
}: {
  themeId: string;
  destinations: UIFlightsThemeRoute[];
  isMobile: boolean;
}) {
  const i18n = useI18n();

  const onCarouselNext = useCallback(() => {
    isMobile
      ? trackCustomGoal("flights_web_cat_theme_based_destinations_mdot", 5)
      : trackCustomGoal("flights_web_cat_theme_based_destinations_www", 5);
  }, [isMobile]);

  return (
    <Carousel
      size={"small"}
      nextButtonAriaLabel={i18n.trans(t("a11y_flights_index_destination_recommendation_next_button_aria_label"))}
      previousButtonAriaLabel={i18n.trans(
        t("a11y_flights_index_destination_recommendation_previous_button_aria_label")
      )}
      onAfterNavigate={onCarouselNext}
    >
      {destinations.map((destination) => (
        <DestinationCard
          key={`theme_destination_${themeId}_${destination.toCity.iata}`}
          isMobile={isMobile}
          {...destination}
        />
      ))}
    </Carousel>
  );
});

const DestinationCard = memo(function _DestinationCard({
  toCity,
  departureDate,
  returnDate,
  tripType,
  isMobile
}: UIFlightsThemeRoute & { isMobile: boolean }) {
  const { createUrlWithApiPrefix } = useCreateUrl();
  const { nearestAirport } = useGlobalContext();
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const toDate = departureDate || format(addMonths(new Date(), 1), "yyyy-MM-dd");

  const searchResultsLink = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set("type", "ONEWAY");
    searchParams.set("adults", "1");
    searchParams.set("cabinClass", "ECONOMY");
    searchParams.set("sort", "BEST");

    searchParams.set("depart", toDate || format(addMonths(new Date(), 1), "yyyy-MM-dd"));
    searchParams.set("return", "");
    searchParams.set("from", nearestAirport ?? "");
    searchParams.set("to", toCity.iata);

    return createUrlWithApiPrefix(`/flights/${nearestAirport}-${toCity.iata}/?${searchParams.toString()}`);
  }, [toCity.iata, nearestAirport, toDate, createUrlWithApiPrefix]);

  const onItemClick = useCallback(() => {
    isMobile
      ? trackCustomGoal("flights_web_cat_theme_based_destinations_mdot", 4)
      : trackCustomGoal("flights_web_cat_theme_based_destinations_www", 4);
  }, [isMobile]);

  return (
    <a href={searchResultsLink} className={styles.card_link} onClick={onItemClick} onAuxClick={onItemClick}>
      <Box padding={0}>
        <AspectRatio ratio="3:2">
          <Image src={toCity.imageUrl} alt="" fallback="background" borderRadius={200} />
        </AspectRatio>
        <div className={styles.card_textContainer}>
          <Text variant="strong_1">{toCity.name}</Text>
          {!!toDate && (
            <Text variant="body_2" color="neutral_alt">
              {returnDate
                ? `${formats.flightDateWeekday(toDate)} - ${formats.flightDateWeekday(returnDate)}`
                : `${formats.flightDateWeekday(new Date(toDate))} ${SPACER_PADDED}`}
              <TripType tripType={tripType} />
            </Text>
          )}
          {/* Save for the next exp iteration */}
          {/* {!!price && (
            <Text variant="strong_2" color="neutral_alt" className={styles.card_textContainer_price}>
              {i18n.trans(
                t("flights_index_destination_recommendation_label_from_price", {
                  variables: { price: formatPrice(price) }
                })
              )}
              {formatPrice(price)}
            </Text>
          )} */}
        </div>
      </Box>
    </a>
  );
});
