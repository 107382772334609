import { UIPointOfSale, AvailableCurrenciesSymbols } from "@flights/types";

type AvailableCurrencies =
  | "euro"
  | "usd"
  | "sek"
  | "nok"
  | "dkk"
  | "gbp"
  | "aud"
  | "uah"
  | "pln"
  | "chf"
  | "qar"
  | "php"
  | "thb"
  | "aed"
  | "clp"
  | "czk"
  | "huf"
  | "isk"
  | "inr"
  | "idr"
  | "bhd"
  | "kwd"
  | "rsd"
  | "omr"
  | "hkd"
  | "cad"
  | "jpy"
  | "bgn"
  | "mxn"
  | "cop"
  | "nzd"
  | "kzt"
  | "pen"
  | "brl"
  | "myr"
  | "sar"
  | "krw";

type CurrencyByPointOfSale = { [key in UIPointOfSale]: AvailableCurrencies };
type CurrencySymbolByPointOfSale = { [key in UIPointOfSale]: AvailableCurrenciesSymbols };

export const currencyByPointOfSale: CurrencyByPointOfSale = {
  de: "euro",
  es: "euro",
  it: "euro",
  nl: "euro",
  ie: "euro",
  at: "euro",
  be: "euro",
  se: "sek",
  no: "nok",
  ro: "euro",
  dk: "dkk",
  fi: "euro",
  us: "usd",
  fr: "euro",
  gb: "gbp",
  au: "aud",
  ua: "uah",
  pl: "pln",
  ch: "chf",
  pt: "euro",
  qa: "qar",
  ph: "php",
  th: "thb",
  ae: "aed",
  cl: "clp",
  cz: "czk",
  hu: "huf",
  is: "isk",
  in: "inr",
  bh: "bhd",
  kw: "kwd",
  rs: "rsd",
  id: "idr",
  om: "omr",
  hk: "hkd",
  lu: "euro",
  ca: "cad",
  jp: "jpy",
  mt: "euro",
  cy: "euro",
  hr: "euro",
  ee: "euro",
  bg: "bgn",
  co: "mxn",
  mx: "cop",
  nz: "nzd",
  gr: "euro",
  kz: "kzt",
  pe: "pen",
  mo: "hkd",
  br: "brl",
  my: "myr",
  sa: "sar",
  kr: "krw"
};

export const currencySymbolByPointOfSale: CurrencySymbolByPointOfSale = {
  de: "EUR",
  es: "EUR",
  it: "EUR",
  nl: "EUR",
  ie: "EUR",
  at: "EUR",
  be: "EUR",
  se: "SEK",
  no: "NOK",
  ro: "EUR",
  dk: "DKK",
  fi: "EUR",
  us: "USD",
  fr: "EUR",
  gb: "GBP",
  au: "AUD",
  ua: "UAH",
  pl: "PLN",
  ch: "CHF",
  pt: "EUR",
  qa: "QAR",
  ph: "PHP",
  th: "THB",
  ae: "AED",
  cl: "CLP",
  cz: "CZK",
  hu: "HUF",
  is: "ISK",
  in: "INR",
  bh: "BHD",
  kw: "KWD",
  rs: "RSD",
  id: "IDR",
  om: "OMR",
  hk: "HKD",
  lu: "EUR",
  ca: "CAD",
  jp: "JPY",
  mt: "EUR",
  cy: "EUR",
  hr: "EUR",
  ee: "EUR",
  bg: "BGN",
  co: "MXN",
  mx: "COP",
  nz: "NZD",
  gr: "EUR",
  kz: "KZT",
  pe: "PEN",
  mo: "HKD",
  br: "BRL",
  my: "MYR",
  sa: "SAR",
  kr: "KRW"
};
