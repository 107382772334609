import { Stack, Text, useTheme } from "@bookingcom/bui-react";
import SearchboxController from "@bookingcom/flights-searchbox/component-controllers/SearchboxController";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import FrequentlyAskedQuestions from "components/elements/FrequentlyAskedQuestions";
import UspBlock from "components/elements/UspBlock";
import WorldwideFlightsMobile from "components/elements/WorldwideFlights/WorldwideFlights";
import React, { Profiler, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { trackMetaLandingsCustomGoalsV2 } from "utils/experiments/track-meta-landings-v2";
import { Search } from "app/routes";
import AppShell from "../components/elements/AppShell";
import useClientMetrics from "hooks/useClientMetrics";
import useGlobalContext from "hooks/useGlobalContext";
import useLocaleContext from "hooks/useLocaleContext";
import useSearchbox from "hooks/useSearchbox";
import useTrackPageLoad from "hooks/useTrackPageLoad";
import { trackExperiment, trackExperimentStage, trackGoal } from "utils/et";
import NoJS from "./NoJS";
import { SearchBoxVerticalDefault } from "@bookingcom/flights-searchbox/components/SearchBox";
import StaticContext from "app/StaticContext/StaticContext";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import Frame from "components/elements/Frame";
import { HomeSearchHeader } from "components/elements/HomeSearchHeader";
import {
  RemoteAppleCardIndex,
  RemoteGeniusLaunchSheet,
  RemoteGeniusLoyalty,
  RemoteSEOInterlinking
} from "components/remote";
import trackPriorityRoutesExp from "utils/experiments/meta/flights_web_remote_component_seo_interlinking";
import { IndexCampaignBanner } from "components/elements/IndexCampaignBanner";
import CheapFlights from "components/elements/CheapFlights";
import ThemeBasedDestinations from "components/elements/ThemeBasedDestinations/ThemeBasedDestinations";
import trackDirectToNonStopEnUs, {
  useTrackDirectToNonstopUs
} from "../utils/experiments/funnel/flights_web_direct_to_nonstop_en_us";
import useRouteName from "hooks/useRouteName";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import { useTrackHomePageTrafficClientAA } from "utils/experiments/customer-aquisition/flights_web_cat_aa_home_page_traffic_client";
import HomeRecentSearchList from "components/elements/HomeRecentSearch/HomeRecentSearchList";
import NoSSR from "app/NoSSR";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import CrowdstrikeOutage from "components/elements/CrowdstrikeOutage";

const Home = () => {
  const { features } = useGlobalContext();
  const staticContext = useContext(StaticContext);
  const theme = useTheme();
  const i18n = useI18n() as I18nChildContext;
  const trackContextEventV2 = useTrackPageLoad("index");
  const {
    userAgent: { isWebview, isBot },
    isKnownBot,
    locale
  } = useGlobalContext();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const { trackRenderTime } = useClientMetrics();
  const routeName = useRouteName();
  const trackLandingPageEvents = useLandingPageEvents();
  const { trackFlyAnywhereMdot } = useTrackFlyAnywhere();

  if (process.env.BUILD_TARGET === "server" && staticContext.set) {
    const { getInitialState } = require("../server/screens/home");
    staticContext.set({ getInitialState });
  }

  useTrackDirectToNonstopUs(routeName);

  useEffect(() => {
    Search.preload();
    trackContextEventV2();
    trackGoal("flights_home_page_view");
    trackLandingPageEvents("page_view", "homepage");

    // track meta users going to index page
    trackMetaLandingsCustomGoalsV2(2, "mdot");
    trackExperimentStage("flights_web_a11y_searchbox", 1); // stage 1 - Desktop + Mdot Index + Search results - a11y searchbox
    trackExperimentStage("flights_web_a11y_searchbox", 3); // stage 3 - Mdot - Index - a11y searchbox

    trackExperimentStage("flights_web_search_header_ui_update_mdot", 1);
    trackExperimentStage("flights_web_search_header_ui_update_mdot", isOfMetaOrigin() ? 3 : 2);

    trackExperimentStage("flights_web_cat_theme_based_destinations_mdot", 2); // main
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackHomePageTrafficClientAA();

  const showSEOInterlinkingComponent = isBot || isKnownBot || !!trackPriorityRoutesExp.variant();

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShell noPadding>
        <Frame alignItems="center" direction="column" justifyContent="center" grow={1}>
          {!!trackExperiment("flights_web_search_header_ui_update_mdot") ? (
            <HomeSearchHeader />
          ) : (
            <Frame
              attributes={{
                style: {
                  backgroundColor: isWebview
                    ? theme.colors.color_background_base
                    : theme.colors.color_background_disabled_alt,
                  width: "100%"
                }
              }}
              pr={4}
              pl={4}
            >
              <NoJS />
              <Frame mt={4} mb={4}>
                <Stack gap={2}>
                  <Text variant="headline_1" tagName="h1">
                    {i18n.trans(t("flights_index_header_compare_book_ease"))}
                  </Text>
                  {!isWebview && (
                    <span style={{ color: theme.colors.color_foreground_alt }}>
                      <Text variant="body_1">{i18n.trans(t("flights_index_header_discover_next_dream"))}</Text>
                    </span>
                  )}
                </Stack>
              </Frame>
              <Frame mb={6}>
                <SearchboxController
                  i18n={i18n}
                  {...searchBoxProps}
                  isRTL={isRTL}
                  showNonStopExp={!!trackDirectToNonStopEnUs.variant() && locale === "en-us"}
                  trackFlyAnywhereMdot={() => trackFlyAnywhereMdot()}
                  onSearch={(searchParams) => {
                    trackLandingPageEvents("click", "searchbox");
                    searchBoxProps.onSearch(searchParams);
                  }}
                >
                  {() => <SearchBoxVerticalDefault />}
                </SearchboxController>
                <CrowdstrikeOutage />
              </Frame>
            </Frame>
          )}

          <NoSSR>
            <HomeRecentSearchList />
          </NoSSR>

          <IndexCampaignBanner />

          {!!trackExperiment("flights_web_cat_theme_based_destinations_mdot") && (
            <ThemeBasedDestinations isMobile={true} />
          )}

          {!!trackExperiment("flights_web_cat_theme_based_destinations_mdot") && <RemoteAppleCardIndex />}

          <CheapFlights isMobile={true} />

          {!trackExperiment("flights_web_cat_theme_based_destinations_mdot") && <RemoteAppleCardIndex />}

          {!!features.FLIGHTS_WEB_CAT_GENIUS_LOYALTY_RC ? <RemoteGeniusLoyalty /> : undefined}

          <Frame attributes={{ style: { width: "100%" } }} mb={6}>
            <UspBlock hideBackground={!!trackExperiment("flights_web_search_header_ui_update_mdot")} />
          </Frame>

          {showSEOInterlinkingComponent ? <RemoteSEOInterlinking isMobile={true} /> : <WorldwideFlightsMobile />}

          <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4}>
            <FrequentlyAskedQuestions />
          </Frame>
        </Frame>
      </AppShell>
      {!!features.FLIGHTS_WEB_CAT_GENIUS_LAUNCH_SHEET_RC ? <RemoteGeniusLaunchSheet /> : undefined}
    </Profiler>
  );
};

function PageHeader(props: { children?: React.ReactNode }) {
  const i18n = useI18n() as I18nChildContext;

  return (
    <Helmet>
      <title>
        {i18n.trans(
          t("mlx_flights_index_meta_title", {
            variables: { b_companyname: "Booking.com" }
          })
        )}
      </title>
      <meta name="description" content={i18n.trans(t("mlx_flights_index_meta_description"))} />
      <meta property="webview:header" content="regular" />
      <meta property="webview:title" content={""} />
      {props.children}
    </Helmet>
  );
}

export default Home;
export { PageHeader };
