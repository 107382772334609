import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline";
import { Banner, SheetContainer } from "@bookingcom/bui-react";
import useGlobalContext from "hooks/useGlobalContext";
import React, { useState } from "react";
import styles from "./CrowdstrikeOutage.module.css";

export default function CrowdstrikeOutage() {
  const [show, setShow] = useState(false);
  const { features } = useGlobalContext();
  return !!features?.FLIGHTS_WEB_HOMEPAGE_CROWDSTRIKE_BANNER ? (
    <>
      <Banner
        dismissible={false}
        variant="hint"
        actions={[{ text: "More details", onClick: () => setShow(true) }]}
        className={styles.ltr}
        startIcon={InfoSignIcon}
        attributes={{ style: { marginTop: "var(--bui_spacing_4x)" } }}
      >
        We are aware of the global IT outage which may have affected some airline and airport systems.
      </Banner>
      <SheetContainer
        title={<div style={{ direction: "ltr", unicodeBidi: "bidi-override" }}>Global IT Outage</div>}
        active={show}
        closeAriaLabel="close"
        onClose={() => setShow(false)}
        onCloseTrigger={() => setShow(false)}
        position="center"
        className={styles.ltr}
      >
        <div style={{ direction: "ltr", unicodeBidi: "bidi-override" }}>
          Whilst systems are slowly recovering, we are working to proactively support our customers with issues they are
          facing and to fix any delays affecting phone calls or the live chat function.
        </div>
      </SheetContainer>
    </>
  ) : null;
}
