import React from "react";

import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { TranslationMessage } from "@bookingcom/lingojs-core/build/t";

import { UITripType } from "@flights/types";

interface Props {
  tripType: UITripType;
}

const TRIP_TYPE_TO_COPY_MAP: { [key in UITripType]: TranslationMessage } = {
  ONEWAY: t("flights_route_one_way"),
  ROUNDTRIP: t("flights_route_round_trip"),
  MULTISTOP: t("flights_multicity_label")
};

const TripType = ({ tripType }: Props) => {
  const i18n = useI18n();

  return <>{i18n.trans(TRIP_TYPE_TO_COPY_MAP[tripType])}</>;
};

export default TripType;
