import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext } from "@bookingcom/lingojs-react";

import { UITraveller, UIPassenger, UIFlightData } from "@flights/types";
import { INFANT_MAX_AGE } from "../../constants";

export const getPassengerByTravellerReference = (
  travellerRef: string,
  passengers: UIPassenger[]
): UIPassenger | undefined => {
  return passengers.find(({ travellerReference }) => travellerReference === travellerRef);
};

const getPassengerNumIndex = (travellerRef: string, passengers: UIPassenger[]): number | undefined => {
  const index = passengers.findIndex(({ travellerReference }) => travellerReference === travellerRef);
  return index >= 0 ? index + 1 : undefined;
};

/**
 * Returns passenger localized full name if passenger has first and last name:
 * "First Last" or "Last First" depending on a locale.
 *
 * Returns anonymous type/age-based passenger title otherwise (i.e. it's no-PAX-cart passenger):
 * "Traveller 1 (adult)", "Traveller 2 (child aged 5)".
 */
export const getPassengerFullNameOrAnonymousTitle = (
  i18n: I18nChildContext,
  passenger: UIPassenger,
  passengersList: UIPassenger[]
): string => {
  const { travellerReference } = passenger;
  const numIndex = getPassengerNumIndex(travellerReference, passengersList);

  return isAnonymousPassenger(passenger)
    ? getPassengerAnonymousTitle(i18n, passenger, numIndex)
    : getPassengerFullName(i18n, passenger);
};

/**
 * Returns anonymous type/age-based passenger title – "Traveller 1 (adult)", "Traveller 2 (child aged 5)"
 * Traveller index is presented if `numIndex` argument is passed.
 */
export const getPassengerAnonymousTitle = (
  i18n: I18nChildContext,
  passenger: UIPassenger,
  numIndex?: number
): string => {
  const { type, age } = passenger;
  const strIndex = typeof numIndex === "number" ? numIndex : "";

  if (type === "ADULT") {
    return i18n.trans(
      t("flights_book_process_travellernum_adult", {
        variables: {
          num_traveller: strIndex
        }
      })
    );
  } else if (type === "KID") {
    return i18n.trans(
      t("flights_book_process_travellernum_child", {
        variables: { num_traveller: strIndex, num_age: String(age) },
        num_exception: age
      })
    );
  } else {
    return "";
  }
};

/**
 * Returns passenger localized full name – "First Last" or "Last First" (depending on a locale)
 */
export const getPassengerFullName = (i18n: I18nChildContext, passenger: UIPassenger): string => {
  const { firstName, lastName } = passenger;

  return i18n.trans(
    t("flights_traveller_full_name", {
      variables: { first_name: firstName, last_name: lastName }
    })
  );
};

export const getPassengerFullNameByReference = (
  i18n: I18nChildContext,
  travellerReference: string,
  passengers: UIPassenger[]
): string => {
  const passenger = getPassengerByTravellerReference(travellerReference, passengers);
  return passenger ? getPassengerFullName(i18n, passenger) : "";
};

export const getFullPassengerByShortTravellerReference = (
  travellerReference: string,
  passengers: UIPassenger[]
): UIPassenger | undefined => {
  const zeroBasedIndex = Number(travellerReference) - 1;
  return passengers[zeroBasedIndex];
};

export const numberOfInfantsOnFlight = (travellers: UITraveller[] | UIPassenger[]): number => {
  return travellers.filter((traveller) => isInfant(traveller)).length;
};

export const doesFlightHaveInfants = (flight: UIFlightData): boolean => {
  return Boolean(flight.travellers?.some((traveller) => isInfant(traveller)));
};

export const isInfant = (traveller: UITraveller | UIPassenger): boolean => {
  const { age } = traveller;
  return typeof age === "number" && age <= INFANT_MAX_AGE;
};

const isAnonymousPassenger = (passenger: UIPassenger): boolean => {
  const { firstName, lastName } = passenger;
  return !firstName && !lastName;
};
