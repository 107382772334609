import React, { FC } from "react";

import { UIOrder } from "@flights/types";
import { Banner, Icon } from "@bookingcom/bui-react";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline";
import { useI18n, t } from "@bookingcom/lingojs-react";

import { areAllPassengerSirfDocsVerified } from "../../../store/selectors/sirf";
import styles from "./SirfWebCheckinReminder.module.css";

type Props = {
  order: UIOrder;
};

const SirfWebCheckinReminder: FC<Props> = ({ order }) => {
  const i18n = useI18n();

  return (
    <Banner className={styles.root} startIcon={<Icon svg={InfoSignIcon} size="large" />} dismissible={false}>
      {areAllPassengerSirfDocsVerified(order)
        ? i18n.trans(t("flights_checkin_approved_message"))
        : i18n.trans(t("flights_checkin_rejected_message"))}
    </Banner>
  );
};
export default SirfWebCheckinReminder;
